import React, { PureComponent } from "react";

import { FiChevronDown, FiInfo } from "react-icons/fi";

import styles from "./accordion.module.less";
import Tooltip from "@components/Tooltip/tooltip";
import { t } from "i18next";

type AccordionProps = {
  children: React.ReactNode;
  accTitle: string;
  openAccordion?: boolean;
  className?: string;
  hasInfo?: boolean;
};

class Accordion extends PureComponent<AccordionProps> {
  state = {
    openAccordion: this.props.openAccordion ? this.props.openAccordion : false,
    className: this.props.className ? this.props.className : "",
    hasInfo: this.props.hasInfo ? this.props.hasInfo : false
  };
  render() {
    return (
      <div
        className={`pt-4 w-full float-left block ${this.state.className} ${styles.accordion} ${
          this.state.openAccordion ? "open" : ""
        }`}
      >
        <div
          className={`${styles.accordion__header} relative justify-between content-center leading-none cursor-pointer`}
          onClick={() =>
            this.setState({
              openAccordion: !this.state.openAccordion,
            })
          }
        >
          <h3 className="relative inline-block font-semibold font-normal-text-color pb-2px pt-1 pr-2 uppercase bg-lighter-gray">
            {this.props.accTitle}
            {this.props.hasInfo && (
              <Tooltip childClassName="inline-flex" content={t("INFO_SEARCH")} direction="left">
                      <FiInfo className="cursor-help inline-block ml-1 text-primary" />
            </Tooltip>
            )}
          </h3>
          <span className="relative inline-block bg-lighter-gray pl-2 float-right">
            <FiChevronDown
              className={`inline-block text-lg text-jonar-blue leading-3 transition duration-300 ${
                this.state.openAccordion ? "transform rotate-180" : "transform rotate-0"
              }`}
            />
          </span>
        </div>
        <div
          className={`${styles.accordion__content} pt-2 block float-left w-full ${
            this.state.openAccordion ? "animate-slide-down" : "animate-slide-up"
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Accordion;

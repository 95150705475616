import { FreightMode, PortType } from "@assets/constants";
import {
  BookingCreatedResponse,
  BookingRequest,
  Commodities,
  CompanyAddress,
  ContainerTemperatures,
  ContainerTypes,
  HazardCodes,
  HSCodes,
  Incoterms,
  JonarAPIResponse,
  PackageTypes,
  Port,
  PortFilters,
  PostalCodeFormat,
  ResourceMaxSizes,
  ShippingTypes,
} from "@assets/types";
import { ArrayUtilities } from "@assets/utilities";
import axios, { AxiosResponse } from "axios";

const JonarAPI = process.env.JonarAPI;

class CreateBookingService {
  getPorts = async (portType: PortType = PortType.SEA): Promise<Port[]> => {
    return await axios
      .get(`${JonarAPI}/ports`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
        params: { portType: portType },
      })
      .then((res: AxiosResponse<JonarAPIResponse<Port[]>>) => {
        return ArrayUtilities.sortAlphabeticallyAscending("FullName", res.data.ReturnItem.Data);
      });
  };

  searchPorts = async (filters: PortFilters): Promise<Port[]> => {
    return await axios
      .post(`${JonarAPI}/ports/search`, filters, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Port[]>>) => {
        return ArrayUtilities.sortAlphabeticallyAscending("FullName", res.data.ReturnItem.Data);
      });
  };

  searchPartnerPorts = async (filters: PortFilters): Promise<Port[]> => {
    return await axios
      .get(`${JonarAPI}/ports/partners/search`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          searchString: filters.searchString,
          size: filters.size,
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Port[]>>) => {
        return ArrayUtilities.sortAlphabeticallyAscending("FullName", res.data.ReturnItem.Data);
      });
  };

  getPortByPointCode = async (pointCode: string, freightMode: string | null): Promise<Port> => {
    return await axios
      .get(`${JonarAPI}/ports/partners/port`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          pointCode: pointCode,
          freightMode: freightMode,
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Port>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  searchPartners = async (searchString: string): Promise<CompanyAddress[]> => {
    return await axios
      .get(`${JonarAPI}/Partner/search/customers/${searchString}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<CompanyAddress[]>>) => {
        return ArrayUtilities.sortAlphabeticallyAscending("FullName", res.data.ReturnItem.Data);
      });
  };

  getCompanyAddresses = async (
    partnerID: string,
    addressTypes: string
  ): Promise<CompanyAddress[]> => {
    return await axios
      .get(`${JonarAPI}/partner/${partnerID}/addresses?addressTypes=${addressTypes}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<CompanyAddress[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getCompanyCustomerAddresses = async (
    partnerID: string,
    addressTypes: string
  ): Promise<CompanyAddress[]> => {
    return await axios
      .get(`${JonarAPI}/partner/${partnerID}/customers?addressTypes=${addressTypes}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<CompanyAddress[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getPartnerAddress = async (partnerCode: string, addressType: string): Promise<CompanyAddress> => {
    return await axios
      .get(`${JonarAPI}/partner/${partnerCode}/partneraddress?addressType=${addressType}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<CompanyAddress>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getShippingTypes = async (): Promise<ShippingTypes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/types`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<ShippingTypes[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getIncoterms = async (): Promise<Incoterms[]> => {
    return await axios
      .get(`${JonarAPI}/registry/incoterms`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Incoterms[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getIncotermByCode = async (code: string) => {
    return await axios
      .get(`${JonarAPI}/registry/incoterm/${code}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Incoterms>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getContainerTemperatures = async (): Promise<ContainerTemperatures[]> => {
    return await axios
      .post(
        `${JonarAPI}/container/temperatures`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: true,
        }
      )
      .then((res: AxiosResponse<JonarAPIResponse<ContainerTemperatures[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getContainerTypes = async (): Promise<ContainerTypes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/containertypes`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<ContainerTypes[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getContainerTypeByCode = async (code: string) => {
    return await axios
      .get(`${JonarAPI}/registry/containertype/${code}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<ContainerTypes>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getPackageTypes = async (): Promise<PackageTypes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/packagetypes`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<PackageTypes[]>>) => {
        return ArrayUtilities.sortAlphabeticallyAscending("Description", res.data.ReturnItem.Data);
      });
  };

  getPackageTypeByCode = async (code: string) => {
    return await axios
      .get(`${JonarAPI}/registry/packagetype/${code}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<PackageTypes>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getResourceMaxSizesByContainerType = async (containerType: string): Promise<ResourceMaxSizes> => {
    return await axios
      .get(`${JonarAPI}/registry/resourcemaxsizesbycontainertype?containerType=${containerType}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<ResourceMaxSizes>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getHazardCodes = async (): Promise<HazardCodes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/hazardcodes?limit=100`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<HazardCodes[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getHazardCodesByJobReference = async (
    jobReference: string,
    sequence: number
  ): Promise<HazardCodes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/hazardcodes/${jobReference}/${sequence}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<HazardCodes[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  searchHazardCodes = async (searchString: string): Promise<HazardCodes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/hazardcodes/search/${searchString}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<HazardCodes[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getHSCodes = async (): Promise<HSCodes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/hscodes?limit=100`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<HSCodes[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  searchHSCodes = async (searchString: string): Promise<HSCodes[]> => {
    return await axios
      .get(`${JonarAPI}/registry/hscodes/${searchString}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<HSCodes[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getCommodities = async (): Promise<Commodities[]> => {
    return await axios
      .get(`${JonarAPI}/registry/commodities`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Commodities[]>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getCommodityByCode = async (code: string) => {
    return await axios
      .get(`${JonarAPI}/registry/commodity/${code}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<Commodities>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  getPostalCodeFormatByCountry = async (country: string) => {
    return await axios
      .get(`${JonarAPI}/registry/postalcodeformatbycountry/${country}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<PostalCodeFormat>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  createBooking = async (booking: BookingRequest): Promise<BookingCreatedResponse> => {
    return await axios
      .post(`${JonarAPI}/createBooking`, booking, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<JonarAPIResponse<BookingCreatedResponse>>) => {
        return res.data.ReturnItem.Data;
      })
      .catch((error) => {
        const errorRes: BookingCreatedResponse = {
          JobReference: "",
          ControlNumber: "",
          DocumentReference: "",
          IsImportAirFreight: false,
          ErrorMessage: "Error occurred when processing the request",
        };
        if (error.response) {
          errorRes.ErrorMessage = error.response.data;
        }
        return errorRes;
      });
  };

  getPortforPool = async (
    cityPointCode: string,
    portType: "POD" | "POL" | "FROM" | "TO",
    freightMode: FreightMode
  ) => {
    return await axios
      .get(`${JonarAPI}/ports/${cityPointCode}/port/${freightMode}`, {
        params: {
          portType: portType,
        },
      })
      .then((res: AxiosResponse<JonarAPIResponse<Port>>) => {
        return res.data.ReturnItem.Data;
      });
  };

  sendBookingEmail = async (booking: BookingRequest): Promise<string> => {
    return await axios
      .post(`${JonarAPI}/email/bookingconfirmation`, booking, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<string>) => {
        return res.data;
      });
  };

  acceptTerms = async (type: string, reference: string): Promise<string> => {
    const body = {
      Type: type,
      Reference: reference,
    };
    return await axios
      .post(`${JonarAPI}/Terms/accept`, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      })
      .then((res: AxiosResponse<string>) => {
        return res.data;
      });
  };
}
export default new CreateBookingService();

import React, { useEffect } from "react";

import { FreightMode, PageType, ShippingType } from "@assets/constants";
import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { AddCargoData, HSCodes, SelectOption, ShippingTypes } from "@assets/types";
import { LabelMapperUtilities } from "@assets/utilities";
import Button from "@components/Button/button";
import CargoCell from "@components/ContainerCell/cargoCell";
import AsyncSelect from "@components/Select/asyncSelect";
import SelectEx from "@components/Select/selectEx";
import Spinner from "@components/Spinner/spinner";
import Tooltip from "@components/Tooltip/tooltip";
import CreateBookingService from "@services/CreateBookingService";
import { commoditiesState, getCommodities } from "@state/slices/commoditiesSlice";
import { containerTypesState, getContainerTypes } from "@state/slices/containerTypesSlice";
import { getHazardCodes, hazardCodesState } from "@state/slices/hazardCodesSlice";
import { getHSCodes, hsCodesState } from "@state/slices/hsCodesSlice";
import { getPackageTypes, packageTypesState } from "@state/slices/packageTypesSlice";
import { shippingTypesState } from "@state/slices/shippingTypesSlice";
import { useTranslation } from "react-i18next";

type CargoInformationPageProps = {
  shippingTypes: ShippingTypes[];
  shipmentType: FreightMode;
  shippingType: string | null;
  selectedHSCodesList: HSCodes[];
  selectedHSCodes: (hsCodes: HSCodes[]) => void;
  onChangeShippingType: (shippingType: string) => void;
  addCargoData: AddCargoData[][];
  onChangeCargoData: (addCargoData: AddCargoData, containerIndex: number | null) => void;
  onDeleteCargoData: (cargoIndex: number, containerIndex: number | null) => void;
  onEditCargoData: (
    cargoIndex: number,
    containerIndex: number | null,
    addCargoData: AddCargoData
  ) => void;
  setSelectedTab: (selectedTab: number) => void;
  onHandleContinue: () => void;
  cargoLinesError: string | null;
  loadingCopyBooking: boolean;
  pageType?: PageType;
};

const CargoInformationComponent: React.FC<CargoInformationPageProps> = (
  props: CargoInformationPageProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const containerTypes = useAppSelector(containerTypesState).containerTypes;
  const loadingContainerTypes = useAppSelector(containerTypesState).loadingContainerTypes;
  const packageTypes = useAppSelector(packageTypesState).packageTypes;
  const loadingPackageTypes = useAppSelector(packageTypesState).loadingPackageTypes;
  const hazardCodes = useAppSelector(hazardCodesState).hazardCodes;
  const loadingHazardCodes = useAppSelector(hazardCodesState).loadingHazardCodes;
  const commodities = useAppSelector(commoditiesState).commodities;
  const loadingCommodities = useAppSelector(commoditiesState).loadingCommodities;
  const hsCodes = useAppSelector(hsCodesState).hsCodes;
  const loadingHSCodes = useAppSelector(hsCodesState).loadingHSCodes;
  const loadingShippingTypes = useAppSelector(shippingTypesState).loadingShippingTypes;

  useEffect(() => {
    dispatch(getContainerTypes());
    dispatch(getPackageTypes());
    dispatch(getHazardCodes());
    dispatch(getHSCodes());
    dispatch(getCommodities());
  }, []);

  const onChangeHSCodes = (value: HSCodes[]) => {
    props.selectedHSCodes(value);
  };

  const getTypes = (shippingType: string | null) => {
    return shippingType?.includes("L") ? packageTypes : containerTypes;
  };

  const getShippingType = (shippingType: string | null) => {
    if (props.shipmentType === FreightMode.AI) return ShippingType.LCL;
    return shippingType === ShippingType.FCL || shippingType?.includes("F")
      ? ShippingType.FCL
      : ShippingType.LCL;
  };

  const onChangeShippingType = (shippingType: string | null) => {
    props.onChangeShippingType(getShippingType(shippingType) ?? "");
    getTypes(shippingType);
  };

  const searchHSCodes = (searchString: string): Promise<HSCodes[]> => {
    return CreateBookingService.searchHSCodes(searchString);
  };

  const shippingTypeDisabled = props.addCargoData[0]?.length > 0;

  if (props.pageType === PageType.COPY && props.loadingCopyBooking) {
    return <Spinner className="w-full flex align-center justify-center" />;
  }

  return (
    <div className="m-6">
      <Tooltip
        content={t("INFO_DISABLED_SHIPPINGTYPE")}
        direction="topLeft"
        hidden={!shippingTypeDisabled}
      >
        <div className="flex mb-6">
          {props.shipmentType === FreightMode.SH && (
            <SelectEx
              className="mb-6 text-base font-normal w-full md:w-1/5"
              disabled={shippingTypeDisabled}
              isLoading={loadingShippingTypes}
              label={t("LABEL_SHIPPING_TYPE")}
              name="shipping type"
              onChange={(e: SelectOption<string>) => {
                onChangeShippingType(e.value);
              }}
              options={props.shippingTypes.map((s) => ({
                value: `${s.First} ${s.Second}`,
                label: s.First == "L" ? ShippingType.LCL : ShippingType.FCL,
              }))}
              placeholder={t("TEXT_SELECT_SHIPMENT_TYPE")}
              tooltipContent={t("INFO_SHIPPINGTYPE")}
              value={
                props.shippingType
                  ? {
                      value: props.shippingType,
                      label: getShippingType(props.shippingType),
                    }
                  : null
              }
            />
          )}
        </div>
      </Tooltip>
      <CargoCell
        cargoLinesError={props.cargoLinesError}
        className={`${
          props.shipmentType === FreightMode.SH ? "border-b-2 border-t-2" : ""
        } relative`}
        commodities={commodities}
        containerTypes={containerTypes}
        data={props.addCargoData}
        freightMode={props.shipmentType}
        getShippingType={getShippingType}
        hazardCodes={hazardCodes}
        loadingCommodities={loadingCommodities}
        loadingContainerTypes={loadingContainerTypes}
        loadingHazardCodes={loadingHazardCodes}
        loadingPackageTypes={loadingPackageTypes}
        onChangeCargoData={props.onChangeCargoData}
        onDeleteCargoData={props.onDeleteCargoData}
        onEditCargoData={props.onEditCargoData}
        packageTypes={packageTypes}
        shippingTypeValue={props.shippingType}
      />
      <div className="border-b-2 mt-8">
        <div className="mb-6 ">
          <AsyncSelect
            className="text-base font-normal"
            defaultOptions={hsCodes}
            getOptionLabel={LabelMapperUtilities.hsCodesLabelMapper}
            getOptionValue={LabelMapperUtilities.hsCodesValueMapper}
            isLoading={loadingHSCodes}
            isMulti
            label={t("LABEL_HSCODES")}
            loadOptions={searchHSCodes}
            name="unit"
            onChange={onChangeHSCodes}
            optional
            placeholder={t("TEXT_SELECT_HS_CODES")}
            tooltipContent={t("INFO_HSCODE")}
            value={props.selectedHSCodesList}
          />
        </div>
      </div>
      <div className="m-6 flex justify-end">
        <Button className="rounded mr-2" onClick={() => props.setSelectedTab(0)}>
          {t("LABEL_BACK")}
        </Button>
        <Button className="ml-2 rounded" onClick={() => props.onHandleContinue()} primary>
          {t("LABEL_CONTINUE")}
        </Button>
      </div>
    </div>
  );
};

export default CargoInformationComponent;

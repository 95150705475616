import React, { useState } from "react";

import { ErrorTypes, ModalProps, UploadDocumentsResponse } from "@assets/types";
import { TranslationUtilities } from "@assets/utilities";
import Button from "@components/Button/button";
import IconButton from "@components/Button/iconButton";
import InputEx from "@components/Input/inputEx";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import SingleBookingService from "@services/SingleBookingService";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";

import Modal from "./modal";

interface SendDocumentsEmailProps extends ModalProps {
  documents: UploadDocumentsResponse[];
  selectedDocumentsIndex: UploadDocumentsResponse[];
}

interface SendDocumentsEmailForm {
  email: string;
}

const defaultSendDocumentsEmailForm: SendDocumentsEmailForm = {
  email: "",
};

const SendDocumentsEmailModal: React.FC<SendDocumentsEmailProps> = (
  props: SendDocumentsEmailProps
) => {
  const { t } = useTranslation();
  const { open, closeModal } = props;

  const [emails, setEmails] = useState<string[]>([]);

  const schema = yup.object({
    email: yup.string().required(ErrorTypes.RequiredField).email(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: defaultSendDocumentsEmailForm,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const resetStatus = () => {
    setEmails([]);
    reset();
  };

  const onClose = () => {
    resetStatus();
    closeModal();
  };
  const onSubmit = async () => {
    const listEtags = props.selectedDocumentsIndex.map((x) => x.ETag);
    SingleBookingService.sendDocumentsByEmail({
      FileNumbers: listEtags,
      Emails: emails,
    })
      .then(() => {
        toast.success(t("LABEL_SEND_EMAIL_SUCCESS"));
      })
      .catch(() => {
        toast.error(t("LABEL_SEND_EMAIL_FAILURE"));
      });
    resetStatus();
    closeModal();
    return;
  };

  const onAddEmails = (email: string) => {
    setEmails([...emails, email]);
    setValue("email", defaultSendDocumentsEmailForm.email);
  };

  const onDeleteEmails = (index: number) => {
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1, emails.length)]);
  };

  const documentsSection = props.selectedDocumentsIndex.map((documentIndex, index) => (
    <li className="my-2 border-2 p-2 rounded" key={`document-${index}`}>
      <span className="self-center">{documentIndex.Name}</span>
    </li>
  ));

  const emailsSection = emails.map((email, index) => (
    <li className="my-2 border-2 p-1 rounded flex justify-between" key={`email-${index}`}>
      <span className="self-center">{email}</span>
      <IconButton active={false} onClick={() => onDeleteEmails(index)}>
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>
    </li>
  ));

  return (
    <Modal className="left-2 right-2" onClose={() => onClose()} open={open} size="xlarge">
      <Modal.Header>{t("LABEL_EMAIL_SELECTED_DOCUMENTS")}</Modal.Header>
      <Modal.Content>
        <form>
          <div>
            <h4>{t("LABEL_DOCUMENTS")}</h4>
            <ul>{documentsSection}</ul>
          </div>
          <div>
            <h4>{t("LABEL_RECIPENTS")}</h4>
            <div className="flex gap-2 mb-6">
              <InputEx
                className="w-full"
                inputmode="email"
                label={t("LABEL_EMAIL")}
                type="text"
                {...register("email")}
                errorMessage={TranslationUtilities.getErrorMessage("email", errors)}
                hasError={errors.email != null}
                id="email"
                placeholder={t("TEXT_ENTEREMAIL")}
              />
              <Button
                className="mt-7"
                onClick={handleSubmit((data: SendDocumentsEmailForm) => onAddEmails(data.email))}
                primary
              >
                {t("LABEL_ADD")}
              </Button>
            </div>
            <ul>{emailsSection}</ul>
          </div>
        </form>
      </Modal.Content>
      <Modal.Footer divider>
        <Button
          className="ml-2"
          onClick={() => {
            onClose();
          }}
          type="button"
        >
          {t("LABEL_CANCEL")}
        </Button>
        <Button
          className="mr-6 md:mr-2"
          disabled={emails.length <= 0}
          onClick={() => onSubmit()}
          primary
          type="submit"
        >
          {t("LABEL_SEND")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendDocumentsEmailModal;

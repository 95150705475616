import React, { useRef, useEffect, useState } from "react";

import { ClassNameUtilities } from "@assets/utilities";

import Tooltip, { TooltipProps } from "./tooltip";

interface OverflowTipProps extends Omit<TooltipProps, "children"> {
  /** Children must be only string or number to have the width calculations correct. */
  children: string | number;
  isTextVisible: boolean;
}
const OverflowTip = ({ children, isTextVisible, childClassName, ...rest }: OverflowTipProps) => {
  const textElementRef = useRef<HTMLDivElement>(null);

  const [showTooltip, setShowTooltip] = useState(false);

  const compareElementSize = () => {
    // If the element has a larger scroll width(the entire width of element, including non viewable)
    // than client width(the visible content without border and scrollbar)
    // then there is hidden content and the tooltip should be shown
    const compare = textElementRef.current
      ? textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      : false;
    setShowTooltip(compare);
  };

  // Compare the element size on component mount and add a listener when resizing the window
  useEffect(() => {
    compareElementSize();
    window.addEventListener("resize", compareElementSize);
    return () => {
      window.removeEventListener("resize", compareElementSize);
    };
  }, []);
  return (
    <Tooltip {...rest} childClassName={childClassName} hidden={!showTooltip}>
      <div
        className={ClassNameUtilities.classNames(
          isTextVisible ? "whitespace-normal" : "dot-dot-dot",
          childClassName
        )}
        ref={textElementRef}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;

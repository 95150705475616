import React, { useEffect, useRef } from "react";

import { useOutsideAlerter } from "@assets/hooks";
import { CallbackFunction } from "@assets/types";
import { ClassNameUtilities } from "@assets/utilities";
import IconButton from "@components/Button/iconButton";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type ModalProps = {
  /** The elements inside the modal. */
  children?: React.ReactElement | React.ReactElement[];
  /** Override or extend the style applied to the modal card. */
  className?: string;
  /** If true, a close icon is showed*/
  closeIcon?: boolean;
  /** If true, the component will close when clicked outside of it. */
  closeOnOutsideClick?: boolean;
  /** The function called when closing the modal. */
  onClose?: CallbackFunction;
  /** If true, the modal is shown. */
  open?: boolean;
  /** The sizes of the modal */
  size?: "small" | "medium" | "large" | "xlarge";
};

export interface ModalHeaderProps {
  /** The elements inside the header. */
  children?: React.ReactNode;
  /** Override or extend the style applied to the modal header. */
  className?: string;
}

export interface ModalContentProps {
  /** The elements inside the the content of modal. */
  children?: React.ReactNode;
  /** Override or extend the style applied to the modal content. */
  className?: string;
}

export interface ModalFooterProps {
  /** The elements inside the footer. */
  children?: React.ReactNode;
  /** Override or extend the style applied to the modal footer. */
  className?: string;
  /** If true the modal is displayed with a divided footer. */
  divider?: boolean;
}

const sizes = {
  small: "w-95",
  medium: "w-146.5",
  large: "w-172.5",
  xlarge: "w-198",
};

export const Modal: React.FC<ModalProps> & {
  Header: React.ElementType;
} & {
  Content: React.ElementType;
} & {
  Footer: React.ElementType;
} = ({
  children,
  className,
  closeIcon = true,
  closeOnOutsideClick = true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  open = false,
  size = "medium",
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClose, closeOnOutsideClick);

  // Stop scrolling of the page underneath when the modal is opened
  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [open]);

  return open ? (
    <div
      className={`w-full h-full
        fixed top-0 left-0 z-200
        flex justify-center items-center
        bg-neutral-200/70 transition-colors pointer-events-auto`}
      id="overlay"
    >
      <div
        className={ClassNameUtilities.classNames(
          `relative shadow bg-neutral-0
          md:rounded-xl`,
          sizes[size],
          className
        )}
        ref={wrapperRef}
      >
        {closeIcon && (
          <IconButton active={false} className="absolute right-6 top-6" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
        {children}
      </div>
    </div>
  ) : null;
};

const Header: React.FC<ModalHeaderProps> = ({ children, className }) => (
  <div
    className={ClassNameUtilities.classNames(
      `md:rounded-t-xl 
      pt-6 px-6 pb-0.5 
      text-xl uppercase text-neutral-900 font-semibold`,
      className
    )}
  >
    {children}
  </div>
);

const Content: React.FC<ModalContentProps> = ({ children, className }) => (
  <div className={ClassNameUtilities.classNames("p-6 max-h-80screen", className)}>{children}</div>
);

const Footer: React.FC<ModalFooterProps> = ({ children, className, divider = false }) => {
  return (
    <div
      className={ClassNameUtilities.classNames(
        `w-full pb-6 px-6 
        flex gap-3 justify-end
        md:rounded-b-xl`,
        divider ? "border-t border-neutral-500 pt-6" : "pt-2",
        className
      )}
    >
      {children}
    </div>
  );
};

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;

import React, { useEffect, useState } from "react";

import {
  FreightMode,
  MAX_INPUT_LENGTH_ADDITIONAL_INFO,
  MAX_INPUT_LENGTH_ADDRESS,
  MAX_INPUT_LENGTH_GENERIC,
  PortType,
} from "@assets/constants";
import {
  CompanyAddress,
  ErrorTypes,
  ModalProps,
  PickPartnerChangeRegistration,
  Port,
  PostalCodeFormat,
  RequestCollectionRegistration,
} from "@assets/types";
import {
  DateUtilities,
  StringUtilities,
  LabelMapperUtilities,
  TranslationUtilities,
} from "@assets/utilities";
import Alert from "@components/Alert/alert";
import Button from "@components/Button/button";
import DatePicker from "@components/DatePicker/datePicker";
import Checkbox from "@components/Input/checkbox";
import InputEx from "@components/Input/inputEx";
import Modal from "@components/Modal/modal";
import AsyncSelect from "@components/Select/asyncSelect";
import TimeInput from "@components/TimePicker/timeInput";
import WithFormController from "@components/WithFormController/WithFormController";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateBookingService from "@services/CreateBookingService";
import { FieldError, useForm } from "react-hook-form";
import { TFunction, Translation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import * as yup from "yup";

interface RequestCollectionProps extends ModalProps {
  companyAddresses: CompanyAddress[];
  onChangeData: (data: RequestCollectionRegistration) => void;
  data?: RequestCollectionRegistration | null;
  userData: PickPartnerChangeRegistration | null;
  isEdit?: boolean;
  isCollection?: boolean;
  shipmentType?: FreightMode;
  isLoadingPartner?: boolean;
  isLoadingCity?: boolean;
}

interface RequestCollectionForm {
  partner: CompanyAddress | null;
  companyName: string;
  address: string;
  postalCode: string;
  placeCity: Port | null;
  district: string;
  extra: string;
  contact: string;
  email: string;
  phone: string;
  collectionDate: Date | null;
  from: string;
  to: string;
  collectionReference: string;
  additionalInfo: string;
}

const defaultFormValues: RequestCollectionForm = {
  partner: null,
  companyName: "",
  address: "",
  postalCode: "",
  placeCity: null,
  district: "",
  extra: "",
  contact: "",
  email: "",
  phone: "",
  collectionDate: null,
  from: "",
  to: "",
  collectionReference: "",
  additionalInfo: "",
};

const RequestCollectionModal: React.FC<RequestCollectionProps> = (
  props: RequestCollectionProps
) => {
  const { companyAddresses, data, onChangeData, open, closeModal, userData } = props;

  const [isSameAs, setIsSameAs] = useState(false);
  const [defaultPlaceCities, setDefaultPlaceCities] = useState<Port[]>([]);
  const refTime: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const [postalCodeFormat, setPostalCodeFormat] = useState<PostalCodeFormat | null>(null);

  const schema = yup.object({
    companyName: yup.string().required(ErrorTypes.RequiredField),
    address: yup
      .string()
      .required(ErrorTypes.RequiredField)
      .max(MAX_INPUT_LENGTH_ADDRESS, ErrorTypes.MaxLengthExceeded),
    postalCode: yup.lazy(() => {
      if (postalCodeFormat) {
        return yup
          .string()
          .required(ErrorTypes.RequiredField)
          .matches(new RegExp(`^${postalCodeFormat.PostalCodeRegexFormat}$`), {
            message: ErrorTypes.PostalCodeError,
            excludeEmptyString: true,
          });
      }
      return yup.string().required(ErrorTypes.RequiredField);
    }),
    placeCity: yup.object().required(ErrorTypes.RequiredField).typeError(ErrorTypes.RequiredField),
    contact: yup.string().required(ErrorTypes.RequiredField),
    email: yup
      .string()
      .required(ErrorTypes.RequiredField)
      .test({
        name: "invalidEmails",
        exclusive: false,
        params: {},
        message: ErrorTypes.EmailIsNotValid,
        test: function (value: string) {
          return StringUtilities.validEmails(value);
        },
      }),
    phone: yup.string().max(MAX_INPUT_LENGTH_GENERIC, ErrorTypes.MaxLengthExceeded),
    collectionDate: yup.mixed().optional(),
    from: yup.lazy(() => {
      if (props.isCollection) {
        return yup.string().optional().matches(StringUtilities.validTimeRegex, {
          message: ErrorTypes.TimeValidation,
          excludeEmptyString: true,
        });
      }
      return yup.string().optional();
    }),
    to: yup.lazy(() => {
      if (props.isCollection) {
        return yup.string().optional().matches(StringUtilities.validTimeRegex, {
          message: ErrorTypes.TimeValidation,
          excludeEmptyString: true,
        });
      }
      return yup.string().optional();
    }),
    collectionReference: yup.lazy(() => {
      if (props.isCollection) {
        return yup
          .string()
          .required(ErrorTypes.RequiredField)
          .max(MAX_INPUT_LENGTH_GENERIC, ErrorTypes.MaxLengthExceeded);
      }
      return yup.string().optional();
    }),
    additionalInfo: yup
      .string()
      .max(MAX_INPUT_LENGTH_ADDITIONAL_INFO, ErrorTypes.MaxLengthExceeded),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  useEffect(() => {
    CreateBookingService.getPorts(PortType.SEA).then((localPorts: Port[]) => {
      setDefaultPlaceCities(localPorts);
    });
  }, []);

  useEffect(() => {
    setValue("partner", data?.partner ? data?.partner : null);
    setValue("companyName", data?.companyName ?? "");
    setValue("address", data?.address ?? "");
    setValue("postalCode", data?.postalCode ?? "");
    setValue("placeCity", data?.placeCity ? data?.placeCity : null);
    setValue("contact", data?.contact ?? "");
    setValue("email", data?.email ?? "");
    setValue("phone", data?.phone ?? "");
    setValue("district", data?.district ?? "");
    setValue("extra", data?.extra ?? "");
    setValue("collectionDate", data?.collectionDate ?? null);
    setValue("from", data?.from ?? "");
    setValue("to", data?.to ?? "");
    setValue("collectionReference", data?.collectionReference ?? "");
    setValue("additionalInfo", data?.additionalInfo ?? "");

    setIsSameAs(false);
  }, [props]);

  useEffect(() => {
    if (isSameAs) {
      setValue("partner", userData?.partner ? userData?.partner : null);
      setValue("companyName", userData?.companyName ?? "");
      setValue("address", userData?.address ?? "");
      setValue("postalCode", userData?.postalCode ?? "");
      setValue("placeCity", userData?.placeCity ? userData?.placeCity : null);
      setValue("contact", userData?.contact ?? "");
      setValue("email", userData?.email ?? "");
      setValue("phone", userData?.phone ?? "");
      setValue("district", "");
      setValue("extra", "");
      setValue("collectionDate", null);
      setValue("from", "");
      setValue("to", "");
      setValue("collectionReference", "");
      setValue("additionalInfo", "");
    } else {
      setValue("partner", null);
      setValue("companyName", "");
      setValue("address", "");
      setValue("postalCode", "");
      setValue("placeCity", null);
      setValue("contact", "");
      setValue("email", "");
      setValue("phone", "");
      setValue("district", "");
      setValue("extra", "");
      setValue("collectionDate", null);
      setValue("from", "");
      setValue("to", "");
      setValue("collectionReference", "");
      setValue("additionalInfo", "");
    }
  }, [isSameAs]);

  const onClose = () => {
    closeModal();
  };

  const onChange = (option: CompanyAddress) => {
    setValue("partner", option ? option : null);
    setValue("companyName", option?.FullName ?? "");
    setValue("address", option?.Address1 ?? "");
    setValue("postalCode", option?.PostCode ?? "");
    setValue("placeCity", {
      PointCode: option.PointCode,
      FullName: option.City,
      Country: option.CountryName,
      CountryCode: option.CountryCode,
      PortCode: option.PostCode,
      PortType: "W",
      TimeZone: "",
      TimeZoneDiff: 0,
      LocalName: "",
    });
    setValue("contact", option?.ContactName ?? "");
    setValue("email", option?.ContactEmail ?? "");
    setValue("phone", option?.ContactNumber ?? "");
    setValue("district", "");
    setValue("extra", "");
    setValue("collectionDate", null);
    setValue("from", "");
    setValue("to", "");
    setValue("collectionReference", "");
    setValue("additionalInfo", "");

    if (option && option.CountryCode) {
      CreateBookingService.getPostalCodeFormatByCountry(option.CountryCode).then(
        (pc: PostalCodeFormat) => {
          setPostalCodeFormat(pc);
        }
      );
    }
  };

  const searchPort = (searchString: string): Promise<Port[]> => {
    return new Promise((resolve) => {
      CreateBookingService.searchPartnerPorts({
        searchString: searchString,
        size: 10,
      }).then((ports: Port[]) => {
        resolve(ports);
      });
    });
  };

  const onSubmit = (formData: RequestCollectionForm) => {
    const newData = {
      partner: formData.partner ?? null,
      companyName: formData.companyName ?? "",
      address: formData.address ?? "",
      postalCode: formData.postalCode ?? "",
      placeCity: formData.placeCity ?? null,
      contact: formData.contact ?? "",
      email: formData.email ?? "",
      phone: formData.phone ?? "",
      district: formData.district ?? "",
      extra: formData.extra ?? "",
      from: formData.from ?? "",
      to: formData.to ?? "",
      collectionDate: formData.collectionDate
        ? DateUtilities.dateToUTC_WithoutTimezoneChange(formData.collectionDate)
        : null,
      collectionReference: formData.collectionReference ?? "",
      additionalInfo: formData.additionalInfo ?? "",
    };
    onChangeData(newData);
    closeModal();
    return;
  };

  const sendFocus = () => {
    if (getValues("from").length === 5) {
      refTime.current?.focus();
    }
  };

  const onChangeModalData = () => {
    setIsSameAs(!isSameAs);
  };

  const searchPartner = (searchString: string): Promise<CompanyAddress[]> => {
    return new Promise((resolve) => {
      CreateBookingService.searchPartners(searchString).then((ports: CompanyAddress[]) => {
        resolve(ports);
      });
    });
  };

  const onChangePortCity = (port: Port) => {
    if (port && port.CountryCode) {
      CreateBookingService.getPostalCodeFormatByCountry(port.CountryCode).then(
        (pc: PostalCodeFormat) => {
          setPostalCodeFormat(pc);
        }
      );
    }
  };

  const setHeader = (t: TFunction<"translation">): string => {
    let header;
    if (props.isCollection) {
      if (props.isEdit) {
        header = t("LABEL_EDIT_COLLECTION");
      } else {
        header = t("LABEL_REQUEST_COLLECTION");
      }
    } else {
      if (props.isEdit) {
        header = t("LABEL_EDIT_DELIVERY");
      } else {
        header = t("LABEL_REQUEST_DELIVERY");
      }
    }
    return header;
  };

  return (
    <Translation>
      {(t) => (
        <Modal closeOnOutsideClick={props.closeOnOutsideClick} onClose={closeModal} open={open}>
          <Modal.Header>{setHeader(t)}</Modal.Header>
          <Modal.Content className="bg-white grid md:w-full overflow-y-auto md:max-h-80vh">
            <form>
              <div className="mt-6 flex">
                <Checkbox
                  checked={isSameAs}
                  className="pb-1"
                  id="sameAs"
                  label={
                    props.isCollection ? t("LABEL_SAME_AS_SHIPPER") : t("LABEL_SAME_AS_CONSIGNEE")
                  }
                  onChange={() => onChangeModalData()}
                  type="checkbox"
                />
              </div>
              <WithFormController control={control} name="partner">
                <AsyncSelect
                  className="mt-6 mb-3"
                  defaultOptions={companyAddresses}
                  disabled={isSameAs}
                  getOptionLabel={LabelMapperUtilities.partnerLabelMapper}
                  getOptionValue={LabelMapperUtilities.partnerValueMapper}
                  id="partner"
                  isLoading={props.isLoadingPartner}
                  label={t("LABEL_PARTNER")}
                  loadOptions={searchPartner}
                  onChange={onChange}
                  placeholder={t("TEXT_ENTER_PARTNER")}
                />
              </WithFormController>
              <InputEx
                className="my-3"
                label={t("LABEL_COMPANY_NAME")}
                type="text"
                {...register("companyName")}
                disabled={isSameAs}
                errorMessage={TranslationUtilities.getErrorMessage("companyName", errors)}
                hasError={errors.companyName != null}
                id="companyName"
                placeholder={t("TEXT_ENTER_COMPANY_NAME")}
              />
              <InputEx
                className="my-3"
                label={t("LABEL_ADDRESS")}
                type="text"
                {...register("address")}
                disabled={isSameAs}
                errorMessage={TranslationUtilities.getErrorMessage("address", errors, {
                  n: MAX_INPUT_LENGTH_ADDRESS,
                })}
                hasError={errors.address != null}
                id="address"
                placeholder={t("TEXT_ENTERADDRESS")}
              />
              <div className="flex flex-row justify-between my-3">
                <WithFormController control={control} name="placeCity">
                  <AsyncSelect
                    className="mr-3 w-full"
                    defaultOptions={defaultPlaceCities}
                    disabled={isSameAs}
                    errorText={t((errors.placeCity as FieldError)?.message || "")}
                    getOptionLabel={LabelMapperUtilities.portLabelMapper}
                    getOptionValue={LabelMapperUtilities.portValueMapper}
                    hasError={errors.placeCity != null}
                    isLoading={props.isLoadingCity}
                    label={t("LABEL_CITY_COUNTRY")}
                    loadOptions={searchPort}
                    onChange={onChangePortCity}
                    placeholder={t("TEXT_ENTER_CITY")}
                  />
                </WithFormController>
                <InputEx
                  className="ml-3 w-full"
                  label={t("LABEL_POSTALCODE")}
                  type="text"
                  {...register("postalCode")}
                  disabled={isSameAs}
                  errorMessage={TranslationUtilities.getErrorMessage("postalCode", errors)}
                  hasError={errors.postalCode != null}
                  helperDescription={
                    postalCodeFormat && postalCodeFormat?.PostalCodeExample
                      ? t("TEXT_EXAMPLE") + ` ${postalCodeFormat?.PostalCodeExample}`
                      : undefined
                  }
                  id="postalcode"
                  placeholder={t("TEXT_ENTERPOSTCODE")}
                />
              </div>
              <InputEx
                className="my-3"
                label={t("LABEL_DISTRICT")}
                type="text"
                {...register("district")}
                disabled={isSameAs}
                id="district"
                optional
                placeholder={t("TEXT_ENTERDISTRICT")}
              />
              <InputEx
                className="my-3"
                label={t("LABEL_EXTRA")}
                type="text"
                {...register("extra")}
                disabled={isSameAs}
                id="extra"
                optional
                placeholder={t("TEXT_ENTEREXTRA")}
              />
              <InputEx
                className="my-3"
                label={t("LABEL_CONTACT_PERSON_NAME")}
                type="text"
                {...register("contact")}
                errorMessage={TranslationUtilities.getErrorMessage("contact", errors)}
                hasError={errors.contact != null}
                id="contact"
                placeholder={t("TEXT_ENTER_CONTACT_PERSON_NAME")}
              />
              <div className="flex flex-row justify-between  mt-3 mb-6">
                <InputEx
                  className="w-full mr-3"
                  inputmode="email"
                  label={t("LABEL_EMAIL")}
                  type="text"
                  {...register("email")}
                  errorMessage={TranslationUtilities.getErrorMessage("email", errors)}
                  hasError={errors.email != null}
                  id="email"
                  placeholder={t("TEXT_ENTEREMAIL")}
                />
                <InputEx
                  className="w-full ml-3"
                  label={t("LABEL_PHONE")}
                  type="text"
                  {...register("phone")}
                  errorMessage={TranslationUtilities.getErrorMessage("phone", errors, {
                    n: MAX_INPUT_LENGTH_GENERIC,
                  })}
                  hasError={errors.phone != null}
                  id="phone"
                  placeholder={t("TEXT_ENTERPHONE")}
                />
              </div>
              <div className="border my-2" />
              {props.isCollection && (
                <>
                  <WithFormController control={control} name="collectionDate">
                    <DatePicker
                      className="w-full"
                      errorMessage={TranslationUtilities.getErrorMessage("collectionDate", errors)}
                      hasError={errors.collectionDate != null}
                      label={t("LABEL_COLLECTION_DATE")}
                      onChange={(e) => setValue("collectionDate", e)}
                      optional
                      placeholder={t("TEXT_COLLECTION_DATE")}
                      tooltipContent={t("TEXT_COLLECTION_DATE_MODAL")}
                      value={getValues("collectionDate") ?? null}
                    />
                  </WithFormController>
                  <div className="grid grid-cols-4 gap-6">
                    <WithFormController control={control} name="from">
                      <TimeInput
                        error={TranslationUtilities.getErrorMessage("from", errors)}
                        hasError={errors.to != null && errors.from == null}
                        labelValue={t("LABEL_FROM")}
                        onChange={(e) => {
                          setValue("from", e);
                          sendFocus();
                        }}
                        optional
                        type="text"
                        value={getValues("from") ?? null}
                      />
                    </WithFormController>
                    <WithFormController control={control} name="to">
                      <TimeInput
                        error={TranslationUtilities.getErrorMessage("to", errors)}
                        hasError={errors.to != null && errors.from == null}
                        labelValue={t("LABEL_TO")}
                        onChange={(e) => {
                          setValue("to", e);
                        }}
                        optional
                        ref={refTime}
                        type="text"
                        value={getValues("to") ?? null}
                      />
                    </WithFormController>
                  </div>
                  {errors.from != null && errors.to != null && (
                    <div className="text-red-500 my-2">
                      <p>{TranslationUtilities.getErrorMessage("from", errors)}</p>
                    </div>
                  )}
                  <Alert className="py-4 px-2 my-6 text-base flex items-start w-full">
                    <FaInfoCircle className="absolut text-info mr-2 mt-1 w-10" />
                    {t("TEXT_JONAR_CONFIRMATION")}
                  </Alert>
                  <InputEx
                    className="my-3"
                    label={t("LABEL_COLLECTION_REFERENCE")}
                    type="text"
                    {...register("collectionReference")}
                    errorMessage={TranslationUtilities.getErrorMessage(
                      "collectionReference",
                      errors,
                      {
                        n: MAX_INPUT_LENGTH_GENERIC,
                      }
                    )}
                    fullWidth
                    hasError={errors.collectionReference != null}
                    id="collectionref"
                    placeholder={t("TEXT_ENTER_COLLECTION_REFERENCE")}
                    tooltipContent={t("INFO_COLLECTION_REFERENCE")}
                  />
                </>
              )}
              <InputEx
                className="my-3"
                label={t("LABEL_ADDITIONAL_INFORMATION")}
                type="text"
                {...register("additionalInfo")}
                errorMessage={TranslationUtilities.getErrorMessage("additionalInfo", errors, {
                  n: MAX_INPUT_LENGTH_ADDITIONAL_INFO,
                })}
                fullWidth
                hasError={errors.additionalInfo != null}
                id="additionalinfo"
                optional
                placeholder={t("TEXT_ENTER_ADDITIONAL_INFORMATION")}
                tooltipContent={t("INFO_COLLECTION_DELIVERY_MODAL")}
              />
            </form>
          </Modal.Content>
          <Modal.Footer>
            <Button
              className="ml-2"
              onClick={() => {
                onClose();
              }}
              type="button"
            >
              {t("LABEL_CANCEL")}
            </Button>
            <Button className="mr-2" onClick={handleSubmit(onSubmit)} primary type="submit">
              {t("LABEL_SAVE")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Translation>
  );
};

export default RequestCollectionModal;

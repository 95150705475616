/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";

import { ClassNameUtilities } from "@assets/utilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface LinkProps {
  /** The content of the component. */
  children: React.ReactNode;
  /** Override or extend the style applied to the component. */
  className?: string;
  /** If true the component is disabled. */
  disabled?: boolean;
  /** Specifies the link's destination. */
  href?: string;
  icon?: IconProp;
  /** By adding an onClick the component will be a button that looks like a link.
   * href, rel, target won't have any effect.
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#onclick_events
   */
  onClick?: React.MouseEventHandler;
  /** The relationship between the current document and the linked document. */
  rel?: string;
  /** Specifies where to open the linked document. */
  target?: "_blank" | "_self" | "_parent" | "_top" | string;
  color?: "primary" | "neutral-0" | "neutral-700" | "neutral-900";
}

const colors = {
  primary: "text-primary",

  "neutral-0": "text-neutral-0",

  "neutral-700": "text-neutral-700",

  "neutral-900": "text-neutral-900",
};

export const Link: React.FC<LinkProps> = ({
  children,
  className,
  disabled = false,
  href,
  icon,
  onClick,
  rel,
  target = "_self",
  color = "primary",
}) => {
  const joinedClassName = ClassNameUtilities.classNames(
    `cursor-pointer hover:underline
    disabled:text-disabled-600 disabled:hover:no-underline disabled:cursor-default
    font-semibold h-fit`,
    colors[color],
    className
  );
  return (
    <>
      {onClick ? (
        <button className={joinedClassName} disabled={disabled} onClick={onClick} type="button">
          {icon && <FontAwesomeIcon className="pr-1.5" icon={icon} />}
          {children}
        </button>
      ) : (
        <a
          className={ClassNameUtilities.classNames(
            joinedClassName,
            disabled ? "text-disabled-600 hover:no-underline cursor-default" : ""
          )}
          href={!disabled ? href : undefined}
          rel={rel}
          target={target}
        >
          {icon && <FontAwesomeIcon className="mr-1 mt-0.5" icon={icon} />}
          {children}
        </a>
      )}
    </>
  );
};

export default Link;

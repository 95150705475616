import { IncotermPoint } from "@assets/constants";
import { Incoterms, Places, Port } from "@assets/types";

import { ArrayUtilities } from ".";

/**
 * Get the incoterm place based on the selected incoterm and it's PrimaryIncotermPoint and AllowedIncotermsPoints
 * @param selectedIncoterm The selected incoterm
 * @param places Object with PLR, POL, PFD, POD selected port values
 * @returns The possible incoterm place ports
 */
export const getIncotermPorts = (selectedIncoterm: Incoterms | null, places: Places): Port[] => {
  let incotermsPointList: IncotermPoint[] = [];
  if (selectedIncoterm) {
    // If the selected incoterm has PrimaryIncotermPoint defined
    // and it is filled in the form, the incoterm place is set to that one
    if (selectedIncoterm.PrimaryIncotermsPoint && places[selectedIncoterm.PrimaryIncotermsPoint]) {
      incotermsPointList.push(selectedIncoterm.PrimaryIncotermsPoint);
    } else {
      // If the incoterm has no PrimaryIncotermPoint defined or filled out in the form
      // the incoterm place value is taken from the AllowedIncotermsPoints that have values
      incotermsPointList = selectedIncoterm.AllowedIncotermsPoints.filter(
        (incotermPoint: IncotermPoint) => places[incotermPoint]
      );
    }

    // Remove port duplicates
    return ArrayUtilities.uniqueBy(
      incotermsPointList
        .map((pointCode: IncotermPoint) => places[pointCode])
        .filter((x): x is Port => Boolean(x)),
      "PointCode"
    );
  }
  return [];
};

import React, { ChangeEventHandler } from "react";

import { classNames } from "@assets/helperFunctions";
import ValidationError from "@components/ValidationError/validationError";
import Tooltip from "@components/Tooltip/tooltip";
import { FiInfo } from "react-icons/fi";

type CheckboxProps = {
  className?: string;
  value?: string | number;
  name?: string;
  type: "checkbox" | "radio";
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  /** The error message to show. !NOTE: use with hasError=true. */
  errorMessage?: string;
  hasTooltip?: boolean;
  tooltipContent?: string;
};

const filledTextColors = {
  primary: "text-jonar-blue",
  secondary: "text-secondary-500",
};
const borderRadius = {
  checkbox: "rounded",
  radio: "rounded-full",
};
const size = {
  checkbox: "h-6 w-6",
  radio: "h-6 w-6",
};
const labelWeight = {
  checkbox: "font-normal",
  radio: "font-semibold",
};

const Checkbox: React.FC<CheckboxProps> = React.forwardRef(
  (
    {
      className,
      value,
      name,
      type,
      label,
      checked,
      disabled,
      hasError = false,
      errorMessage,
      onChange,
      id,
      hasTooltip = false,
      tooltipContent
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const borderColor = hasError ? "border-error" : "border-neutral-500";

    return (
      <div className={`cursor-pointer relative flex leading-5 ${className}`}>
        <label className="flex items-center" htmlFor={id}>
          <input
            checked={checked}
            className={classNames(
              `appearance-none 
            focus:outline-none focus:ring-0 focus:border-neutral-500
            border inline-grid place-items-center cursor-pointer without-ring`,
              borderColor,
              borderRadius[type],
              size[type],
              filledTextColors["primary"],
              className,
              disabled
                ? "bg-neutral-200 border-neutral-500 cursor-default hover:border-neutral-500 hover:bg-neutral-200"
                : ""
            )}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            ref={ref}
            type={type}
            value={value}
          />
          {label && (
            <label
              className={classNames(
                `cursor-pointer pl-2 text-regular-color align-baseline`,
                labelWeight[type]
              )}
            >
              {label}
            </label>
          )}
        </label>
        {hasTooltip && (
          <Tooltip childClassName="" content={tooltipContent}>
            <FiInfo className="cursor-help inline-block ml-1 text-primary" />
          </Tooltip>
        )}
        {hasError && errorMessage && <ValidationError className="mt-2" errorText={errorMessage} />}
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;

import React from "react";

import { ClassNameUtilities } from "@assets/utilities";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IconButtonUpdatedProps {
  /** Override or extend the style applied to the button. */
  className?: string;
  /** Override or extend the style applied to the FontAwesomeIcon. */
  classNameIcon?: string;
  /** The color of the component */
  color?: "primary" | "neutral-600" | "neutral-700";
  /** If true the component is disabled. */
  disabled?: boolean;
  /** The icon inside the button. */
  icon: IconProp;
  /** Callback fired when the element is clicked. */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** The size of the icon. */
  size?: SizeProp;
}

const iconColors = {
  primary: "text-primary hover:text-primary-600",
  "neutral-600": "text-neutral-600 hover:text-primary",
  "neutral-700": "text-neutral-700 hover: text-primary",
};

export const IconButtonUpdated = ({
  className,
  classNameIcon,
  color = "primary",
  disabled,
  icon,
  onClick,
  size = "1x",
}: IconButtonUpdatedProps) => {
  return (
    <button
      className={ClassNameUtilities.classNames(className)}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <FontAwesomeIcon
        className={ClassNameUtilities.classNames(
          disabled && "text-disabled-text",
          !disabled && iconColors[color],
          classNameIcon
        )}
        icon={icon}
        size={size}
      />
    </button>
  );
};

export default IconButtonUpdated;

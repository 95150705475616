import React, { FC, useState, useEffect } from "react";

import { titlePrefix } from "@assets/constants";
import { StringUtilities } from "@assets/utilities";
import Button from "@components/Button/button";
import Card from "@components/Card/card";
import Input from "@components/Input/input";
import { forgotPassword } from "@state/actions";
import i18n from "i18next";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

import styles from "./resetPassword.module.less";

type ForgotPasswordPageProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  path?: string;
  forgotPassword: Function;
};

const ForgotPassword: FC<ForgotPasswordPageProps> = ({ forgotPassword }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = titlePrefix + i18n.t("MENU_SERVICE_WEB");
  }, []);

  const resetValues = () => {
    setEmail("");
  };

  const handleForgotPassword = async (email: string) => {
    if (!StringUtilities.validEmail(email)) return;

    setLoading(true);
    await forgotPassword(email)
      .then(() => {
        resetValues();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Translation>
      {(t) => (
        <main
          className={`${styles.resetPassword} flex bg-light-gray flex-col flex-grow justify-center items-center w-full`}
        >
          <Card className="w-3/4 lg:w-3/4 max-w-600 bg-white ease-in-out bg-opacity-85 hover:bg-opacity-100 h-auto flip-in-hor-top m-4">
            <h2 className="uppercase pt-2 pl-3 font-bold text-jonar-blue text-xl">
              {t("LABEL_FORGOT_PWD")}
            </h2>
            <form
              autoComplete="on"
              className="container p-5"
              name="loginForm"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="border-b border-gray-300 p-2">
                <Input
                  className="bg-transparent leading-tight  text-gray-700 mr-3"
                  name="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(event.target.value)
                  }
                  placeholder={t("LABEL_EMAIL")}
                  type="email"
                  value={email}
                />
              </div>
              <div className="flex flex-row-reverse justify-between items-center">
                <Button
                  active={loading}
                  className="mt-2"
                  onClick={async () => {
                    await handleForgotPassword(email);
                  }}
                  primary
                  type="submit"
                >
                  {t("LABEL_RESET_PWD")}
                </Button>
              </div>
            </form>
          </Card>
        </main>
      )}
    </Translation>
  );
};

export default connect(
  () => {
    return {};
  },
  { forgotPassword }
)(ForgotPassword);

import React from "react";

import { ClassNameUtilities } from "@assets/utilities";

type HeaderPageProps = {
  children: React.ReactNode;
  className?: string;
  variantWidth?: "full" | "xl";
  backgroundColour?: "white" | "gray";
};

export const HeaderPage: React.FC<HeaderPageProps> = ({
  children,
  className,
  variantWidth = "full",
  backgroundColour = "white",
}) => {
  const joinedClassName = ClassNameUtilities.classNames(
    className,
    variantWidth && variantWidth === "full" ? "w-full" : "w-375px md:max-w-screen-xl md:w-full",
    backgroundColour === "white" ? "bg-white" : "bg-gray-100"
  );

  return <div className={joinedClassName}>{children}</div>;
};

export default HeaderPage;

import React, { useEffect, useState } from "react";

import { FreightMode, ShippingType, Temperatures } from "@assets/constants";
import {
  AddCargoData,
  ContainerTypes,
  PackageTypes,
  ModalProps,
  Commodities,
  HazardCodes,
  ErrorTypes,
  SelectOption,
  AddDimensionsData,
} from "@assets/types";
import { LabelMapperUtilities, TranslationUtilities } from "@assets/utilities";
import { emptyStringToNull } from "@assets/yupValidationHelpers";
import Button from "@components/Button/button";
import Checkbox from "@components/Input/checkbox";
import InputEx from "@components/Input/inputEx";
import Modal from "@components/Modal/modal";
import AsyncSelect from "@components/Select/asyncSelect";
import SelectEx from "@components/Select/selectEx";
import WithFormController from "@components/WithFormController/WithFormController";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateBookingService from "@services/CreateBookingService";
import i18next from "i18next";
import { FieldError, useFieldArray, useForm } from "react-hook-form";
import { Translation } from "react-i18next";
import { FaPlus, FaTrash } from "react-icons/fa";
import * as yup from "yup";

interface AddCargoProps extends ModalProps {
  shippingType: ShippingType;
  data?: AddCargoData;
  onChangeData: (data: AddCargoData, containerIndex: number | null) => void;
  onEditCargoData: (
    cargoIndex: number,
    containerIndex: number | null,
    addCargoData: AddCargoData
  ) => void;
  containerTypes: ContainerTypes[];
  unit: PackageTypes[];
  hazardCodes: HazardCodes[];
  commodities: Commodities[];
  isEdit: boolean;
  cargoIndex: number;
  containerIndex: number | null;
  loadingContainerTypes: boolean;
  loadingPackageTypes: boolean;
  loadingHazardCodes: boolean;
  loadingCommodities: boolean;
  shipmentType: FreightMode;
}

interface AddCargoForm {
  commodity: Commodities | null;
  quantity: number | null;
  unit: PackageTypes | null;
  description: string;
  temperatureControl: boolean;
  temperatureMax: string;
  temperatureMin: string;
  temperature: SelectOption<number[]> | null;
  stackable: boolean;
  dimensions: AddDimensionsData[];
  height: number | null;
  width: number | null;
  length: number | null;
  volume: number | null;
  weight: number | null;
  hazardous: boolean;
  unNumbers: HazardCodes[];
  outOfGauge: boolean;
  marksAndNumbers: string;
}

const defaultFormValues: AddCargoForm = {
  commodity: null,
  quantity: null,
  unit: null,
  description: "",
  temperatureControl: false,
  temperatureMin: "",
  temperatureMax: "",
  temperature: null,
  stackable: false,
  dimensions: [
    {
      height: null,
      width: null,
      length: null,
      quantity: null,
    },
  ],
  height: null,
  width: null,
  length: null,
  volume: null,
  weight: null,
  hazardous: false,
  unNumbers: [],
  outOfGauge: false,
  marksAndNumbers: "",
};

const AddLCLCargoModal: React.FC<AddCargoProps> = (props: AddCargoProps) => {
  const {
    shippingType,
    data,
    open,
    closeModal,
    onChangeData,
    onEditCargoData,
    unit,
    isEdit,
    cargoIndex,
    containerIndex = null,
    shipmentType,
  } = props;

  const [temperatureChecked, setTemperatureChecked] = useState(false);

  const dropdownSchema = {
    label: yup.string(),
    value: yup.mixed(),
  };

  const schema = yup.object({
    commodity: yup
      .object()
      .shape(dropdownSchema)
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    quantity: yup
      .number()
      .nullable()
      .transform(emptyStringToNull)
      .required(ErrorTypes.RequiredField)
      .min(1, ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    unit: yup
      .object()
      .shape(dropdownSchema)
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    description: yup
      .string()
      .max(30, ErrorTypes.DescriptionSizeExceded)
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField),
    temperature: yup.mixed().when("temperatureControl", {
      is: true,
      then: yup
        .object()
        .shape(dropdownSchema)
        .typeError(ErrorTypes.RequiredField)
        .required(ErrorTypes.RequiredField),
    }),
    temperatureControl: yup.mixed().when("containerType", {
      is: (containerType: ContainerTypes) => containerType?.Refrigerated === "Y",
      then: yup.bool().oneOf([true], ErrorTypes.RequiredField),
    }),
    weight: yup
      .number()
      .required(ErrorTypes.RequiredField)
      .typeError(ErrorTypes.RequiredField)
      .min(1, ErrorTypes.RequiredField),
    dimensions: yup.array(
      yup.object({
        height: yup.lazy(() => {
          return yup
            .number()
            .required(ErrorTypes.RequiredField)
            .typeError(ErrorTypes.RequiredField)
            .min(0.1, ErrorTypes.RequiredField);
        }),
        width: yup.lazy(() => {
          return yup
            .number()
            .required(ErrorTypes.RequiredField)
            .typeError(ErrorTypes.RequiredField)
            .min(0.1, ErrorTypes.RequiredField);
        }),
        length: yup.lazy(() => {
          return yup
            .number()
            .required(ErrorTypes.RequiredField)
            .typeError(ErrorTypes.RequiredField)
            .min(0.1, ErrorTypes.RequiredField);
        }),
        quantity: yup.lazy(() => {
          return yup
            .number()
            .required(ErrorTypes.RequiredField)
            .typeError(ErrorTypes.RequiredField)
            .min(0.1, ErrorTypes.RequiredField);
        }),
      })
    ),
    volume: yup.lazy(() => {
      return yup.number().optional();
    }),
    unNumbers: yup.mixed().when("hazardous", {
      is: true,
      then: yup
        .array(yup.object().shape(dropdownSchema))
        .min(1, ErrorTypes.RequiredField)
        .required(ErrorTypes.RequiredField)
        .typeError(ErrorTypes.RequiredField),
    }),
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const {
    fields: dimensions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "dimensions",
  });

  const dimensionsArray = watch("dimensions");

  const calculateQuantity = () => {
    const quantities: number[] = dimensionsArray.map((q) => (q.quantity ? Number(q.quantity) : 0));

    return quantities.reduce((total: number, current: number) => total + current, 0);
  };

  const calculateVolume = () => {
    let totalVolume = 0;

    dimensionsArray.map((item) => {
      const _length = item?.length ? +item.length : 0;
      const _width = item?.width ? +item.width : 0;
      const _height = item?.height ? +item.height : 0;
      const _quantity = item?.quantity ? +item.quantity : 0;

      const volume = (_length * _width * _height * _quantity) / 1000000;
      totalVolume += volume;
    });

    return totalVolume;
  };

  const addCargoLine = () => {
    const newCargoLine: AddDimensionsData = {
      length: null,
      width: null,
      height: null,
      quantity: null,
    };
    append(newCargoLine);
  };

  const deleteCargoline = (index: number) => {
    remove(index);
  };

  const getTemperatureOption = (minTemp?: string, maxTemp?: string) => {
    if (minTemp && maxTemp) {
      const temp = Temperatures.find(
        (x) => x.value[0] === parseInt(minTemp) && x.value[1] === parseInt(maxTemp)
      );
      return temp || null;
    }
    return null;
  };

  useEffect(() => {
    setValue("commodity", data?.commodity ?? null);
    setValue("quantity", data?.quantity ?? null);
    setValue("unit", data?.unit ?? null);
    setValue("description", data?.description ?? "");
    setValue("temperatureControl", data?.temperatureControl ?? false);
    setValue("temperature", getTemperatureOption(data?.temperatureMin, data?.temperatureMax));
    setValue("dimensions", data?.dimensions ?? []);
    setValue("stackable", data?.stackable ?? false);
    setValue("height", data?.height ?? null);
    setValue("weight", data?.weight ?? null);
    setValue("length", data?.length ?? null);
    setValue("width", data?.width ?? null);
    setValue("volume", data?.volume ?? null);
    setValue("hazardous", data?.hazardous ?? false);
    setValue("unNumbers", data?.unNumbers ?? []);
    setValue("outOfGauge", data?.outOfGauge ?? false);
    setValue("marksAndNumbers", data?.marksAndNumbers ?? "");
    setTemperatureChecked(data?.temperatureControl == false ? false : true);
  }, [props]);

  const onClose = () => {
    setTemperatureChecked(false);
    setValue("commodity", null);
    setValue("quantity", null);
    setValue("unit", null);
    setValue("description", "");
    setValue("temperatureControl", false);
    setValue("temperature", null);
    setValue("stackable", false);
    setValue("dimensions", []);
    setValue("height", null);
    setValue("weight", null);
    setValue("length", null);
    setValue("width", null);
    setValue("volume", null);
    setValue("hazardous", false);
    setValue("unNumbers", []);
    setValue("outOfGauge", false);
    setValue("marksAndNumbers", "");
    closeModal();
    reset();
  };

  const searchHazardCodes = (searchString: string): Promise<HazardCodes[]> => {
    return CreateBookingService.searchHazardCodes(searchString);
  };

  const insertStackableIntoMarksAndNumbers = (numbers: string) => {
    let marksAndNumbers = numbers ?? "";

    if (getValues("stackable") == true) {
      marksAndNumbers += " Product is stackable";
    }

    return marksAndNumbers;
  };

  const onSubmit = async (formData: AddCargoForm) => {
    let minTemperature = "";
    let maxTemperature = "";
    if (formData?.temperatureControl) {
      minTemperature = formData?.temperature ? formData?.temperature?.value[0].toString() : "";
      maxTemperature = formData?.temperature ? formData?.temperature?.value[1].toString() : "";
    }

    const newData = {
      commodity: formData.commodity ?? null,
      quantity: calculateQuantity() ?? 0,
      containerType: null,
      containerShipperOwned: false,
      containerNumber: "",
      containerSealNumber: "",
      unit: formData.unit
        ? {
            PackageCode: formData.unit?.PackageCode,
            Description: formData.unit?.Description,
            StackableFlag: formData.unit?.StackableFlag,
            Refrigerated: formData.unit?.Refrigerated,
            DefaultTeus: formData.unit?.DefaultTeus,
          }
        : null,
      description: formData.description ?? "",
      temperatureControl: formData.temperatureControl ?? false,
      temperatureMin: minTemperature,
      temperatureMax: maxTemperature,
      stackable: formData.stackable ?? false,
      dimensions: formData.dimensions ?? [],
      height: formData.height ?? 0,
      weight: formData.weight ?? 0,
      length: formData.length ?? 0,
      width: formData.width ?? 0,
      volume: calculateVolume() ?? 0,
      hazardous: formData.hazardous ?? false,
      unNumbers: formData.unNumbers ?? [],
      outOfGauge: formData.outOfGauge ?? false,
      marksAndNumbers: insertStackableIntoMarksAndNumbers(formData.marksAndNumbers),
    };
    isEdit ? onEditCargoData(cargoIndex, containerIndex, newData) : onChangeData(newData, null);
    closeModal();
  };

  const getErrorMessageDimensions = (field: string, index: number) => {
    const dimensionsErrors = errors.dimensions !== undefined ? errors?.dimensions[index] : null;
    switch (field) {
      case "height": {
        if (dimensionsErrors?.height) {
          return i18next.t(dimensionsErrors?.height.message?.toString() ?? "");
        } else {
          return null;
        }
      }
      case "length": {
        if (dimensionsErrors?.length) {
          return i18next.t(dimensionsErrors?.length.message?.toString() ?? "");
        } else {
          return null;
        }
      }
      case "width": {
        if (dimensionsErrors?.width) {
          return i18next.t(dimensionsErrors?.width.message?.toString() ?? "");
        } else {
          return null;
        }
      }
      case "quantity": {
        if (dimensionsErrors?.quantity) {
          return i18next.t(dimensionsErrors?.quantity.message?.toString() ?? "");
        } else {
          return null;
        }
      }
      default:
        return null;
    }
  };

  const renderDimensionsInput = () => {
    return (
      <Translation>
        {(t) =>
          dimensions.map((item, index) => {
            return (
              <div className="flex md:flex-row flex-col w-full mb-4" key={item.id}>
                <InputEx
                  className="w-4/5 mr-3"
                  label={t("LABEL_QUANTITY")}
                  type="number"
                  {...register(`dimensions.${index}.quantity`, {
                    onChange: () => {
                      setValue("volume", calculateVolume());
                      setValue("quantity", calculateQuantity());
                    },
                  })}
                  errorMessage={getErrorMessageDimensions("quantity", index) ?? ""}
                  hasError={getErrorMessageDimensions("quantity", index) == null ? false : true}
                  id={`quantity${index}`}
                  placeholder={t("TEXT_ADD_QUANTITY")}
                />
                <InputEx
                  className="w-4/5 mr-3"
                  label={t("LABEL_LENGTH")}
                  type="number"
                  {...register(`dimensions.${index}.length`, {
                    onChange: () => {
                      setValue("volume", calculateVolume());
                    },
                  })}
                  errorMessage={getErrorMessageDimensions("length", index) ?? ""}
                  hasError={getErrorMessageDimensions("length", index) == null ? false : true}
                  id={`length${index}`}
                  placeholder={t("TEXT_ADD_UNIT_LENGTH")}
                  unitRight="cm"
                />
                <InputEx
                  className="w-4/5 mx-3"
                  label={t("LABEL_WIDTH")}
                  type="number"
                  {...register(`dimensions.${index}.width`, {
                    onChange: () => {
                      setValue("volume", calculateVolume());
                    },
                  })}
                  errorMessage={getErrorMessageDimensions("width", index) ?? ""}
                  hasError={getErrorMessageDimensions("width", index) == null ? false : true}
                  id={`width${index}`}
                  placeholder={t("TEXT_ADD_UNIT_WIDTH")}
                  unitRight="cm"
                />
                <InputEx
                  className="w-4/5 mx-3"
                  key={`height${index}`}
                  label={t("LABEL_HEIGHT")}
                  type="number"
                  {...register(`dimensions.${index}.height`, {
                    onChange: () => {
                      setValue("volume", calculateVolume());
                    },
                  })}
                  errorMessage={getErrorMessageDimensions("height", index) ?? ""}
                  hasError={getErrorMessageDimensions("height", index) == null ? false : true}
                  id={`height${index}`}
                  placeholder={t("TEXT_ADD_UNIT_HEIGHT")}
                  unitRight="cm"
                />
                <Button
                  className={`flex outline-none border-none justify-self-end self-end${
                    errors.dimensions === undefined
                      ? "justify-self-end self-end"
                      : "justify-self-center self-center"
                  }`}
                  onClick={() => deleteCargoline(index)}
                >
                  <FaTrash />
                </Button>
              </div>
            );
          })
        }
      </Translation>
    );
  };

  return (
    <Translation>
      {(t) => (
        <Modal closeOnOutsideClick={props.closeOnOutsideClick} onClose={closeModal} open={open}>
          <Modal.Header>
            {shippingType === ShippingType.LCL || shipmentType == FreightMode.AI
              ? t("LABEL_ADD_CARGO")
              : t("LABEL_ADD_CONTAINER_AND_CARGO")}
          </Modal.Header>
          <Modal.Content className="bg-white grid md:w-full overflow-y-auto md:max-h-80vh">
            <form>
              <div className="grid md:grid-cols-1">
                <div className="grid md:grid-cols-2">
                  <WithFormController control={control} name="commodity">
                    <SelectEx
                      className=""
                      errorText={TranslationUtilities.getErrorMessage("commodity", errors)}
                      getOptionLabel={LabelMapperUtilities.commodityLabelMapper}
                      getOptionValue={LabelMapperUtilities.commodityValueMapper}
                      hasError={errors.commodity != null}
                      id="commodity"
                      isLoading={props.loadingCommodities}
                      label={t("LABEL_COMMODITY")}
                      options={props.commodities}
                      placeholder={t("TEXT_ADD_COMMODITY")}
                    />
                  </WithFormController>
                  <InputEx
                    className="ml-2 pr-2"
                    label={t("LABEL_DESCRIPTION")}
                    type="text"
                    {...register("description")}
                    errorMessage={TranslationUtilities.getErrorMessage("description", errors)}
                    fullWidth
                    hasError={errors.description != null}
                    id="description"
                    maxLength={30}
                    placeholder={t("TEXT_ADD_DESCRIPTION")}
                    tooltipContent={t("INFO_DESCRIPTION")}
                  />
                </div>
                <div className="flex flex-row w-full mb-6">
                  <WithFormController control={control} name="unit">
                    <SelectEx
                      className={`${shippingType == ShippingType.LCL ? "w-full" : "w-3/6"}
                        justify-center
                        my-3
                        block`}
                      errorText={TranslationUtilities.getErrorMessage("unit", errors)}
                      getOptionLabel={LabelMapperUtilities.packageTypeLabelMapper}
                      getOptionValue={LabelMapperUtilities.packageTypeValueMapper}
                      hasError={errors.unit != null}
                      id="unit"
                      isLoading={props.loadingPackageTypes}
                      label={t("LABEL_UNIT")}
                      options={unit}
                      placeholder={t("TEXT_SELECT_UNIT_TYPE")}
                    />
                  </WithFormController>
                </div>
                <div>
                  <div>
                    {renderDimensionsInput()}
                    <Button
                      className="flex items-center border-none text-ocean-blue"
                      onClick={() => addCargoLine()}
                    >
                      <FaPlus className="mr-2 mb-1" /> {t("LABEL_ADD")}
                    </Button>
                  </div>
                </div>
                <div className="grid grid-cols-4 justify-start">
                  <div>
                    <InputEx
                      className="w-4/5 my-3 mr-3 bg-transparent outline-none"
                      disabled
                      id="totalpackages"
                      isOnlyText
                      label={t("LABEL_TOTAL_PACKAGES")}
                      placeholder="0"
                      type="number"
                      value={calculateQuantity().toString()}
                    />
                  </div>
                  <InputEx
                    className="w-4/5 my-3 bg-transparent outline-none"
                    label={t("LABEL_VOLUME")}
                    placeholder="0"
                    type="number"
                    {...register("volume")}
                    disabled
                    id="volume"
                    isOnlyText
                    unitRight="m3"
                  />
                  <InputEx
                    className="my-3 mr-3"
                    label={t("LABEL_WEIGHT")}
                    type="number"
                    {...register("weight")}
                    errorMessage={TranslationUtilities.getErrorMessage("weight", errors)}
                    hasError={errors.weight != null}
                    id="weight"
                    min={0}
                    placeholder={t("TEXT_ADD_UNIT_WEIGHT")}
                    unitRight="kg"
                  />
                </div>
                <div className="flex flex-col justify-start border-t border-solid border-gray-300 w-full my-5">
                  <label className="my-5 text-base inline-block leading-5.5 font-semibold text-3.25 text-neutral-800">
                    {t("LABEL_SPECIAL_HANDLING")}
                  </label>
                  <div className="flex md:flex-row flex-col justify-between">
                    <Checkbox
                      className="flex flex-col"
                      id="stackable"
                      label={t("LABEL_STACKABLE")}
                      type="checkbox"
                      {...register("stackable")}
                    />
                    <Checkbox
                      className="flex flex-col"
                      id="hazardous"
                      label={t("LABEL_HAZARDOUS")}
                      type="checkbox"
                      {...register("hazardous")}
                    />
                    <Checkbox
                      className="flex flex-col"
                      id="temperatureControl"
                      label={t("LABEL_TEMPERATURE_CONTROL")}
                      type="checkbox"
                      {...register("temperatureControl", {
                        onChange: () => {
                          setTemperatureChecked(!temperatureChecked);
                        },
                      })}
                      errorMessage={TranslationUtilities.getErrorMessage(
                        "temperatureControl",
                        errors
                      )}
                      hasError={errors.temperatureControl != null}
                    />
                  </div>
                </div>
                <div className={`${watch("hazardous") === true ? "" : "hidden"}`}>
                  <WithFormController control={control} name="unNumbers">
                    <AsyncSelect
                      className="my-3 text-base font-normal"
                      defaultOptions={props.hazardCodes}
                      errorText={
                        Array.isArray(errors.unNumbers)
                          ? t((errors.unNumbers[0] as FieldError)?.message as string) || ""
                          : t(ErrorTypes.RequiredField)
                      }
                      getOptionLabel={LabelMapperUtilities.hazardCodesLabelMapper}
                      getOptionValue={LabelMapperUtilities.hazardCodesValueMapper}
                      hasError={errors.unNumbers != null}
                      id="unNumbers"
                      isLoading={props.loadingHazardCodes}
                      isMulti
                      label={t("LABEL_HAZARDOUS")}
                      loadOptions={searchHazardCodes}
                      placeholder={t("TEXT_ADD_HAZARDOUS")}
                    />
                  </WithFormController>
                </div>
                <div
                  className={`${
                    watch("temperatureControl") === true
                      ? "flex flex-row justify-between"
                      : "hidden"
                  }`}
                >
                  <WithFormController control={control} name="temperature">
                    <SelectEx
                      className="mt-6 mb-3 text-base font-normal"
                      errorText={TranslationUtilities.getErrorMessage("temperature", errors)}
                      hasError={errors.temperature != null}
                      label={t("LABEL_TEMPERATURE_RANGE")}
                      options={Temperatures}
                      placeholder={t("TEXT_SELECT_TEMPERATURE_RANGE")}
                      portaling
                    />
                  </WithFormController>
                </div>
                <div className="w-full border-t border-solid border-gray-300">
                  <InputEx
                    className="my-3"
                    label={t("LABEL_MARKS_AND_NUMBERS")}
                    type="text"
                    {...register("marksAndNumbers")}
                    fullWidth
                    id="marksAndNumbers"
                    optional
                    placeholder={t("TEXT_ADD_MARKS_AND_NUMBERS")}
                    tooltipContent={t("INFO_MARKS_AND_NUMBERS")}
                  />
                </div>
              </div>
            </form>
          </Modal.Content>
          <Modal.Footer>
            <Button
              className="ml-2"
              onClick={() => {
                onClose();
              }}
              type="button"
            >
              {t("LABEL_CANCEL")}
            </Button>
            <Button className="mr-2" onClick={handleSubmit(onSubmit)} primary>
              {t("LABEL_ADD")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Translation>
  );
};

export default AddLCLCargoModal;

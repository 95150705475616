import React from "react";

import { ClassNameUtilities } from "@assets/utilities";

interface ValidationErrorProps {
  /** Override or extend the style applied to the component. */
  className?: string;
  /** The error text to show. */
  errorText: string;
}

const ValidationError = ({ errorText, className }: ValidationErrorProps) => {
  return (
    <p className={ClassNameUtilities.classNames("text-error leading-4.5 text-xs", className)}>
      {errorText}
    </p>
  );
};

export default ValidationError;

import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@assets/hooks";
import { Invoice } from "@assets/types";
import { StringUtilities } from "@assets/utilities";
import { Link } from "@components/Link/link";
import Page from "@components/Page/page";
import { ActionTable } from "@samskip/frontend-components/";
import { ActionTableProps } from "@samskip/frontend-components/dist/components/Table/actionTable";
import { invoiceState, getInvoicesUrls } from "@state/slices/invoicesSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface InvoicesPageProps {
  jobReference: string;
  sapID: string;
}

const InvoicesComponent: React.FC<InvoicesPageProps> = (props: InvoicesPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const invoices = useAppSelector(invoiceState).invoices;

  useEffect(() => {
    if (invoices.length === 0) {
      dispatch(
        getInvoicesUrls({
          jobReference: props.jobReference,
          sapID: props.sapID,
        })
      );
    }
  }, [window.location.href.split("#")[1] === "Invoices"]);

  const getInvoiceColumns = () => {
    const invoiceColumns: ActionTableProps<Invoice>["columns"] = [
      {
        dataIndex: "InvoiceID",
        id: "invoiceID",
        title: t("LABEL_INVOICE"),
        sortable: true,
      },
      {
        dataIndex: "Amount",
        id: "amount",
        title: t("LABEL_AMOUNT"),
        sortable: true,
        render: (invoice) =>
          invoice.Amount
            ? StringUtilities.numberCommaDecimal(parseInt(invoice.Amount))
            : t("LABEL_NA"),
      },
      {
        dataIndex: "Curreny",
        id: "curreny",
        title: t("LABEL_CURRENCY"),
        sortable: true,
      },
      {
        dataIndex: "Date",
        id: "date",
        title: t("LABEL_DATE"),
        render: (invoice) => moment(invoice.Date).format("DD MMM YYYY").toString(),
        sortable: true,
      },
      {
        dataIndex: "",
        id: "link",
        title: "",
        render: (invoice) => (
          <Link
            className="text-jonar-blue"
            key={`view_invoice-${invoice.InvoiceID}`}
            onClick={() => window.open(invoice.Url ?? "")}
          >
            {t("LABEL_VIEW_INVOICE")}
          </Link>
        ),
      },
    ];
    return invoiceColumns;
  };

  return (
    <Page className="gap-6 flex flex-col justify-center items-start shadow-sm rounded-sm mb-6 md:w-1204px w-375px">
      <div className="w-full">
        {invoices && invoices.length == 0 ? (
          <div className="text-neutral-700 px-8 py-6 text-sm">
            {t("TEXT_NO_INVOICES_AVAILABLE")}
          </div>
        ) : (
          <div className="mx-8 my-6">
            <ActionTable
              columns={getInvoiceColumns()}
              getRowId={(invoice) => invoice.InvoiceID}
              headerTextCase="none"
              id="invoice"
              isBordered={false}
              rows={invoices}
              scrollable={false}
            />
          </div>
        )}
      </div>
    </Page>
  );
};

export default InvoicesComponent;
